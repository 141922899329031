<template>
  <b-row>
    <template
      v-if="_.size(itemIngredientsOptions) == 0 && isLoadingStarted == false"
    >
      <b-col :sm="12" class="text-right">
        <b-button variant="outline-success" @click="loadItems()"
          ><i class="flaticon-plus"></i> {{ $t("ADD") }}
        </b-button>
      </b-col>
    </template>
    <template
      v-if="_.size(itemIngredientsOptions) > 0 && isLoadingStarted == false"
    >
      <b-col :sm="4">
        <b-form-select
          :options="itemIngredientsOptions"
          :value="selectedItemIngredient"
          @input="mutateSelectedItemIngredient"
        >
        </b-form-select>
      </b-col>
      <b-col :sm="8">
        <template v-if="_.size(selectedItemIngredient) > 0">
          <b-input-group class="mb-3" v-bind:key="index">
            <b-form-input
              v-model="tempNewIngredient.ingredientUnitValue"
              type="number"
              :step="selectedItemIngredient.unitNumberType == 2 ? 1 : 0.000001"
              min="0"
              class="text-right"
            ></b-form-input>
            <template v-slot:append>
              <b-input-group-text
                ><strong class="text-danger">{{
                  selectedItemIngredient.ingredientUnitShortName
                }}</strong></b-input-group-text
              >
            </template>
          </b-input-group>
        </template>
        <div>
          <b-button
            variant="light-success"
            class="mt-3 float-right"
            @click="addItemRecipeIngredient()"
            :disabled="_.size(selectedItemIngredient) == 0"
            ><i class="flaticon-plus"></i>{{ $t("ADD") }}</b-button
          >
        </div>
      </b-col>
    </template>
    <template v-if="isLoadingStarted == true">
      <b-col :sm="12" class="text-right">
        <v-progress-circular indeterminate size="24"></v-progress-circular>
      </b-col>
    </template>
  </b-row>
</template>
<script>
import _ from "lodash";
export default {
  name: "AddItemRecipeIngredient",
  inheritAttrs: false,
  props: {
    /**
     */
    selectedItemIngredient: Object,
    selectedItemIngredientProducts: {
      type: Object,
      default: function () {
        return {};
      }
    },
    itemIngredientsOptions: Array,
    isLoadingStarted: {
      type: Boolean,
      default: false
    },
    newIngredient: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    tempNewIngredient: {
      get: function () {
        return this.newIngredient;
      },
      set: function (newValue) {
        this.$emit("update:newIngredient", newValue);
      }
    }
  },
  methods: {
    getIngredientProductvalue(id) {
      let vm = this;
      let ingredientProduct = _.find(vm.product, { id: id });
      if (_.size(ingredientProduct) > 0) {
        return ingredientProduct.unitValue;
      } else {
        return 0;
      }
    },
    loadItems(value) {
      this.$emit("loadItems", value);
    },
    mutateSelectedItemIngredient(value) {
      //  trigger a mutation, or dispatch an action
      this.$emit("mutateSelectedItemIngredient", value);
    },
    addItemRecipeIngredient() {
      this.$emit("addItemRecipeIngredient");
    }
  }
};
</script>
