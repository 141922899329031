<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-items' }">{{
            $t("ALL")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['items-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingItem"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col sm>
            <v-text-field
              :value="itemName"
              :label="$t('NAME')"
              outlined
              disabled
              readonly
            ></v-text-field>
          </b-col>
        </b-row>
        <v-tabs
          v-if="!firstLoader"
          background-color="white"
          color="primary accent-4"
          centered
          icons-and-text
        >
          <v-tab>
            {{ $t("DETAILS") }}
            <v-icon>mdi-hamburger</v-icon></v-tab
          >

          <v-tab v-if="form.isRecipe == true">
            {{ $t("RECIPE") }}
            <v-badge
              :value="_.size(form.itemRecipeMainIngredients) == 0"
              color="orange"
              content="!"
              overlap
            >
              <v-icon>mdi-file-document-box</v-icon>
            </v-badge>
          </v-tab>

          <v-tab>
            {{ $t("STORES") }}

            <v-badge
              :value="_.size(form.stores) == 0"
              color="orange"
              content="!"
              overlap
            >
              <v-icon>mdi-home</v-icon>
            </v-badge>
          </v-tab>

          <v-tab>
            {{ $t("SUPPLIERS") }}
            <v-icon>mdi-truck-fast</v-icon></v-tab
          >

          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col>
                <b-form>
                  <FormBuilder
                    v-if="!firstLoader"
                    :form="form"
                    :schemaJson="schemaJson"
                    :v="$v"
                  ></FormBuilder>
                  <SkeletonLoaderMain
                    :visible="firstLoader"
                  ></SkeletonLoaderMain>
                </b-form>
              </b-col>
            </b-row>
          </v-tab-item>
          <v-tab-item
            v-if="form.isRecipe == true"
            :transition="false"
            :reverse-transition="false"
          >
            <b-row class="mt-5">
              <template v-if="!firstLoader && form.isRecipe == true">
                <b-col :sm="12">
                  <b-row>
                    <b-col :sm="12">
                      <h3>
                        {{ $t("MAIN_INGREDIENTS") | capitalize }}
                      </h3>
                    </b-col>
                    <b-col :sm="6">
                      <AddItemRecipeIngredientComponent
                        :selectedItemIngredient="itemMainIngredients.selected"
                        :itemIngredientsOptions="itemMainIngredientsOptions"
                        :isLoadingStarted="itemMainIngredients.loading"
                        :newIngredient.sync="newIngredient"
                        @loadItems="getItemMainIngredients"
                        @mutateSelectedItemIngredient="
                          itemMainIngredients.selected = $event
                        "
                        @addItemRecipeIngredient="addItemRecipeMainIngredient"
                      >
                      </AddItemRecipeIngredientComponent>
                      <ItemRecipeIngredientsListComponent
                        :uniquePrefix="'mainIngredient'"
                        :itemRecipeIngredients="form.itemRecipeMainIngredients"
                        @ingredient-input="
                          handleIngredientInput(
                            'itemRecipeMainIngredients',
                            $event
                          )
                        "
                        @delete-item-recipe-ingredient="
                          deleteItemRecipeMainIngredient
                        "
                      >
                      </ItemRecipeIngredientsListComponent>
                    </b-col>
                  </b-row>
                </b-col>
              </template>
            </b-row>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col :lg="12">
                <CheckboxList
                  :heading="$t('STORES')"
                  :items="stores"
                  :selectedItems.sync="form.stores"
                  :modelName="'stores'"
                  :firstLoader="firstLoader"
                  :checkboxLoader="isLoadingSharedStore"
                  :labelKey="'nameWithCity'"
                  :sm="4"
                >
                </CheckboxList>
                <SkeletonLoaderCheckboxes
                  :visible="firstLoader"
                ></SkeletonLoaderCheckboxes>
              </b-col>
            </b-row>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col :lg="12">
                <CheckboxList
                  :heading="$t('SUPPLIERS')"
                  :items="suppliers"
                  :selectedItems.sync="form.suppliers"
                  :modelName="'suppliers'"
                  :firstLoader="firstLoader"
                  :checkboxLoader="isLoadingSharedSupplier"
                >
                </CheckboxList>
                <SkeletonLoaderCheckboxes
                  :visible="firstLoader"
                ></SkeletonLoaderCheckboxes>
              </b-col>
            </b-row>
          </v-tab-item>
        </v-tabs>
        <v-overlay
          :value="!firstLoader && (isLoadingItem || _.size(itemTypes) == 0)"
        >
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['items-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingItem"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_ITEM,
  FETCH_INGREDIENTS,
  UPDATE_ITEM,
  DELETE_ITEM
} from "@/modules/item/store/item.module";

import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import i18nService from "@/core/services/i18n.service.js";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import CheckboxList from "@/view/content/forms/components/CheckboxList.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

//Service
import arrayHelperService from "@/core/services/arrayHelper.service.js";
import { FETCH_STORES_BASIC } from "@/core/services/store/shared_store.module";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

import AddItemRecipeIngredientComponent from "@/modules/item/components/AddItemRecipeIngredient.vue";
import ItemRecipeIngredientsListComponent from "@/modules/item/components/ItemRecipeIngredientsList.vue";
import _ from "lodash";

export default {
  name: "ItemEdit",
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      item: {},
      itemMainIngredients: {
        selected: {},
        options: [],
        products: [],
        loading: false
      },
      itemMainIngredientsParams: {
        itemId: null
      },
      suppliers: [],
      stores: [],
      units: [],
      itemTypes: [],
      isEdit: true,
      newIngredient: {
        ingredientUnitValue: 0
      }
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown,
    CheckboxList,
    AddItemRecipeIngredientComponent,
    ItemRecipeIngredientsListComponent
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Items", route: { name: "list-items" } },
      { title: "Edit item" }
    ]);
    vm.getItem();
    vm.getSelects();
    vm.getStores();
  },
  watch: {},
  computed: {
    ...mapGetters([
      "isLoadingItem",
      "isLoadingSharedSupplier",
      "isLoadingSharedStore",
      "currentUser"
    ]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
    itemName() {
      let vm = this;
      if (_.size(vm.form) > 0) {
        let keyLang = "name:" + vm.activeLanguage;
        let name = vm.form[keyLang];
        let itemTypeName = vm.item.itemTypeName;
        return name + " (" + itemTypeName + ")";
      }
      return "";
    },
    itemMainIngredientsOptions() {
      let vm = this;
      let options = [];
      if (_.size(vm.itemMainIngredients.options) > 0) {
        options = [{ value: {}, text: "Избери:" }];
        let group = { label: "Съставки", options: [] };
        vm.itemMainIngredients.options.forEach(function(ingredient) {
          let ingredientOption = {
            value: ingredient,
            text: ingredient.name
          };
          group.options.push(ingredientOption);
        });
        options.push(group);
      }
      return options;
    },
    schemaJson() {
      return {
        fields: [
          {
            type: "radio",
            groupId: "item-item-type-group",
            id: "item-item-type-input",
            inputType: "radio",
            feedback_id: "item-item-type-live-feedback",
            colSm: "3",
            divider: true,
            rowHorizontal: true,
            i18n: {
              label: "ITEM_TYPE",
              placeholder: "ITEM_TYPE"
            },
            options: this.itemTypes,
            validations: {
              required: true
            },
            model: "itemTypeId"
          },
          {
            type: "switch",
            groupId: "item-isRecipe-group",
            id: "item-isRecipe-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "isRecipe-live-feedback",
            colSm: "auto",
            divider: true,
            i18n: {
              label: "IS_RECIPE",
              placeholder: "IS_RECIPE"
            },
            validations: {
              required: true
            },
            model: "isRecipe"
          },
          {
            type: "switch",
            groupId: "item-hasLot-group",
            id: "item-hasLot-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "hasLot-live-feedback",
            colSm: "auto",
            divider: false,
            i18n: {
              label: "HAS_LOT",
              placeholder: "HAS_LOT"
            },
            validations: {
              required: true
            },
            model: "hasLot"
          },
          {
            type: "input",
            inputType: "number",
            id: "item-code-input",
            groupId: "item-code-group",
            required: "required",
            feedback_id: "item-code-live-feedback",
            divider: false,
            i18n: {
              label: "CODE",
              placeholder: "CODE",
              validation: "VALID_CODE"
            },
            validations: {
              required: true,
              minLength: 1
            },
            translatable: false,
            model: "code"
          },
          {
            type: "input",
            inputType: "text",
            id: "item-name-input",
            groupId: "item-name-group",
            required: "required",
            feedback_id: "item-name-live-feedback",
            divider: false,
            i18n: {
              label: "NAME",
              placeholder: "NAME",
              validation: "VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 1
            },
            translatable: true,
            model: "name"
          },
          {
            type: "input",
            inputType: "number",
            id: "item-defaultCost-input",
            groupId: "item-defaultCost-group",
            required: "required",
            feedback_id: "item-defaultCost-live-feedback",
            divider: false,
            i18n: {
              label: "DEFAULT_COST",
              placeholder: "DEFAULT_COST"
            },
            validations: {
              required: true,
              decimal: true,
              minValue: 0
            },
            translatable: false,
            model: "defaultCost"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-warehouse-group",
            id: "item-warehouse-input",
            feedback_id: "warehouse-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "ITEM_WAREHOUSE",
              placeholder: "ITEM_WAREHOUSE"
            },
            options: this.warehouses,
            validations: {
              required: true
            },
            model: "warehouseId"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-package-group",
            id: "item-package-input",
            feedback_id: "package-live-feedback",
            divider: true,
            clearable: false,
            i18n: {
              label: "ITEM_PACKAGE",
              placeholder: "ITEM_PACKAGE"
            },
            options: this.packages,
            validations: {
              required: true
            },
            model: "packageId"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            feedback_id: "unit-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "UNIT",
              placeholder: "UNIT"
            },
            options: this.units,
            validations: {
              required: true
            },
            model: "unitId"
          },
          {
            type: "input",
            inputType: "number",
            id: "item-unitValue-input",
            groupId: "item-unitValue-group",
            required: "required",
            feedback_id: "item-unitValue-live-feedback",
            divider: false,
            i18n: {
              label: "UNIT_VALUE_IN_PACKAGE",
              placeholder: "UNIT_VALUE_IN_PACKAGE"
            },
            validations: {
              required: true,
              decimal: true,
              minValue: 0
            },
            translatable: false,
            model: "unitValue"
          },
          {
            type: "input",
            inputType: "number",
            id: "item-weight-input",
            groupId: "item-weight-group",
            required: "required",
            feedback_id: "item-weight-live-feedback",
            divider: false,
            i18n: {
              label: "WEIGHT",
              placeholder: "WEIGHT"
            },
            validations: {
              required: true,
              decimal: true,
              minValue: 0
            },
            translatable: false,
            model: "weight"
          },
          {
            type: "input",
            inputType: "number",
            id: "days-for-order-input",
            groupId: "days-for-order-input-group",
            required: "required",
            feedback_id: "days-for-order-input-feedback",
            divider: false,
            i18n: {
              label: "DAYS_FOR_ORDER",
              placeholder: "DAYS_FOR_ORDER"
            },
            validations: {
              required: true,
              decimal: true,
              minValue: 0
            },
            translatable: false,
            model: "daysForOrder"
          },
          {
            type: "switch",
            groupId: "item-isRecipe-group",
            id: "item-isRecipe-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "isRecipe-live-feedback",
            colSm: "auto",
            divider: true,
            i18n: {
              label: "IS_DOUGH",
              placeholder: "IS_DOUGH"
            },
            validations: {
              required: true
            },
            model: "isDough"
          },
          {
            type: "switch",
            groupId: "item-isRecipe-group",
            id: "item-isRecipe-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "isRecipe-live-feedback",
            colSm: "auto",
            divider: false,
            i18n: {
              label: "IS_CONSUMPTION",
              placeholder: "IS_CONSUMPTION"
            },
            validations: {
              required: true
            },
            model: "isConsumption"
          },
          {
            type: "input",
            inputType: "text",
            id: "item-echo-name-input",
            groupId: "item-echo-name-group",
            required: "required",
            feedback_id: "item-live-feedback",
            divider: true,
            i18n: {
              label: "ECHO_NAME",
              placeholder: "ECHO_NAME",
              validation: "ECHO_NAME"
            },
            validations: {},
            translatable: false,
            model: "echoName"
          },
          {
            type: "input",
            inputType: "text",
            id: "item-echo-id-input",
            groupId: "item-echo-id-group",
            required: "required",
            feedback_id: "item-live-feedback",
            divider: false,
            i18n: {
              label: "ECHO_ID",
              placeholder: "ECHO_ID",
              validation: "ECHO_ID"
            },
            validations: {},
            translatable: false,
            model: "echoId"
          }
        ]
      };
    }
  },
  validations() {
    // let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    return tmpValidationObject;
  },
  methods: {
    getItem() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_ITEM, id)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.item = data.data;
            this.generateFormOutOfSchemaJson(this.schemaJson, vm.item).then(
              () => {
                vm.$set(vm.form, "itemTypeId", vm.item.itemTypeId);
                vm.$set(vm.form, "suppliers", vm.item.suppliers);
                vm.$set(vm.form, "stores", vm.item.stores);
                vm.$set(
                  vm.form,
                  "itemRecipeMainIngredients",
                  vm.item.itemRecipeMainIngredients
                );

                vm.prepareChangesCheck();
              }
            );
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-items" });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getItemMainIngredients() {
      let vm = this;
      let params = this.itemMainIngredientsParams;
      params.itemId = this.$route.params.id;

      let apiParams = vm.transformUrlParams(params);
      vm.itemMainIngredients.loading = true;
      this.$store
        .dispatch(FETCH_INGREDIENTS, apiParams)
        .then(data => {
          vm.itemMainIngredients.options = data.data;
        })
        .catch(response => {
          console.log(response);
        })
        .finally(() => {
          vm.itemMainIngredients.loading = false;
        });
    },
    getSelects() {
      let vm = this;
      let payload = {
        units: {
          fields: ["id", "name"],
          search: ""
        },
        item_types: {
          fields: ["id", "name"],
          search: ""
        },
        stores: {
          fields: ["id", "name", "nameWithCity"],
          search: ""
        },
        suppliers: {
          fields: ["id", "name"],
          search: ""
        },
        packages: {
          fields: ["id", "name"],
          search: ""
        },
        warehouses: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.units = data.data.units;
          vm.itemTypes = data.data.item_types;
          vm.suppliers = data.data.suppliers;
          vm.packages = data.data.packages;
          vm.warehouses = data.data.warehouses;
        })
        .catch(response => {
          console.log(response);
        });
    },

    getStores() {
      let vm = this;
      let params = { full: true };
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_BASIC, apiParams)
        .then(data => {
          this.$set(vm, "stores", data.data);
        })
        .catch(response => {
          console.log(response);
        });
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      let payload = this.form;
      payload = {
        ...payload,
        products: vm.item.products
      };
      this.$store
        .dispatch(UPDATE_ITEM, {
          id: vm.item.id,
          payload: payload
        })
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });

          vm.prepareChangesCheck().then(() => {
            // let itemEdit = data.data;
            if (next == "continue") {
              //New
              // this.$router.push({
              //   name: "edit-item",
              //   params: { id: itemEdit.id }
              // });
            }
            if (next == "new") {
              //New
              this.$router.push({ name: "add-item" });
            }
            if (next == "exit") {
              this.$router.push({ name: "list-items" });
            }
          });
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    onDelete() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(DELETE_ITEM, vm.item.id)
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });

              vm.prepareChangesCheck().then(() => {
                vm.$router.push({ name: "list-items" });
              });
            })
            .catch(function(response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    resetVForm() {
      this.$v.$reset();
    },
    deleteItemRecipeMainIngredient(data) {
      let ingredientId = data.ingredientId;
      let ingredientObject = "itemRecipeMainIngredients";
      this.deleteItemRecipeIngredient(ingredientId, ingredientObject);
    },
    deleteItemRecipeIngredient(ingredientId, ingredientObject) {
      let vm = this;
      vm.form[ingredientObject].splice(
        vm.form[ingredientObject].findIndex(
          ingredient => ingredient.ingredientId === ingredientId
        ),
        1
      );
    },
    addItemRecipeMainIngredient() {
      let ingredientObjectKey = "itemRecipeMainIngredients";
      let itemIngredientsKey = "itemMainIngredients";
      this.addItemRecipeIngredient(ingredientObjectKey, itemIngredientsKey);
    },
    addItemRecipeIngredient(ingredientObjectKey, itemIngredientsKey) {
      let vm = this;
      //newItem is = selected ingredient + ingredientUnitValue
      let newItem = { ...vm[itemIngredientsKey].selected };
      newItem.ingredientUnitValue = vm.newIngredient.ingredientUnitValue;
      arrayHelperService.addOrUpdate(
        vm.form[ingredientObjectKey],
        newItem,
        "ingredientId",
        this.$set
      );
    },
    handleIngredientInput(ingredientType, ingredientData) {
      let vm = this;
      vm.form[ingredientType][ingredientData.index][ingredientData.data.prop] =
        ingredientData.data.value;
    },
    toggleItem(itemData) {
      let vm = this;
      let checkboxValue = itemData.checkboxValue;
      let checkboxStore = itemData.checkboxStore;
      let storeId = checkboxStore.id;
      if (checkboxValue == true) {
        var newStore = {
          id: storeId,
          products: []
        };
        vm.form.stores.push(newStore);
      } else {
        vm.form.stores.splice(
          vm.form.stores.findIndex(item => item.id === storeId),
          1
        );
      }
    }
  }
};
</script>
