<template>
  <b-row>
    <b-col :sm="12">
      <template v-for="(ingredient, index) in itemRecipeIngredients">
        <!-- <div style="background:silver;padding:5px;"> -->
        <ItemRecipeIngredientsListItemComponent
          v-bind:key="uniquePrefix + 'ingredient' + index"
          :ingredient="ingredient"
          @ingredientInput="handleIngredientInput(index, $event)"
          @deleteItemRecipeIngredient="
            $emit('delete-item-recipe-ingredient', $event)
          "
        >
        </ItemRecipeIngredientsListItemComponent>
        <!-- </div> -->
      </template>
    </b-col>
  </b-row>
</template>
<script>
import ItemRecipeIngredientsListItemComponent from "@/modules/item/components/ItemRecipeIngredientsListItem.vue";
export default {
  name: "ItemRecipeIngredientsList",
  inheritAttrs: false,
  props: {
    /**
     */
    itemRecipeIngredients: Object,

    /** */
    uniquePrefix: String
  },
  data() {
    return {};
  },
  components: { ItemRecipeIngredientsListItemComponent },
  mounted() {},
  computed: {},
  methods: {
    handleIngredientInput(i, data) {
      this.$emit("ingredient-input", {
        index: i,
        data: data
      });
    }
  }
};
</script>
