<template>
  <div>
    <b-input-group class="mt-3">
      <template v-slot:prepend>
        <b-input-group-text>
          <v-chip
            small
            close
            @click:close="deleteItemRecipeIngredient(ingredient.ingredientId)"
          >
            {{ ingredient.name }}
          </v-chip></b-input-group-text
        >
      </template>
      <b-form-input
        :value="ingredient.ingredientUnitValue"
        @input="handleIngredientInput('ingredientUnitValue', $event)"
        type="number"
        :step="ingredient.unitNumberType == 2 ? 1 : 0.000001"
        min="0"
        class="text-right mt-2 mb-2"
      ></b-form-input>
      <template v-slot:append>
        <b-input-group-text
          ><strong class="text-danger">{{
            ingredient.ingredientUnitShortName
          }}</strong></b-input-group-text
        >
      </template>
    </b-input-group>
    <hr />
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "ItemRecipeIngredientsListItem",
  inheritAttrs: false,
  props: {
    ingredient: Object
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    handleIngredientInput(prop, value) {
      this.$emit("ingredientInput", {
        prop: prop,
        value: value
      });
    },
    deleteItemRecipeIngredient(ingredientId) {
      this.$emit("deleteItemRecipeIngredient", { ingredientId: ingredientId });
    }
  }
};
</script>
