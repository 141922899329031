const arrayHelperService = {
  addOrUpdate(array, item, index, setCallback = null) {
    // (1)

    const i = array.findIndex(_item => _item[index] === item[index]);
    if (i > -1) {
      if (setCallback) {
        setCallback(array, i, item);
      } else {
        array[i] = item; // (2)
      }
    } else {
      array.push(item);
    }
    return array;
  }
};

export default arrayHelperService;
